<template>
  <div class="btn-group dropright">
    <button type="button" class="bi bi-three-dots-vertical" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: black">

    </button>
    <div class="dropdown-menu">
      <!-- Dropdown menu links -->
      <button class="btn bi bi-plus" @click="updateModal">Add to groups</button>
      <router-link v-if="showReportDropdown" :to="{name: 'CertificationReport', params: {idUser: idUser}}">
        <div class="dropdown-divider"></div>

        <button class="btn bi bi-bar-chart-fill">User Reports</button>
      </router-link>
    </div>
  </div>
  <div v-if="showModal">
    <Teleport to="body">
      <UserGroupMenu
          :id-user="selectedUserId"
          :groups="groups"
          @close="showModal = false"
      />
    </Teleport>
  </div>
</template>

<script>
import {ref, onBeforeMount, computed, onMounted} from "vue";
import UserGroupMenu from "@/views/SuperUserViews/UserGroups/UserGroupMenu";

export default {
  name: "UserMenuManagementDropdown",
  components: {UserGroupMenu},
  props: {
    idUser: String,
    userName: String,
    groups: Array,
    showReportDropdown: Boolean
  },
  setup(props) {
    const showModal = ref(false);

    onBeforeMount(() => {
      // const button = document.querySelector('#button')
      // const dropdown = document.querySelector('#dropdown')
      // createPopper(button, dropdown)
    })

    onMounted(() => {
      // const button = document.querySelector('#button')
      // const dropdown = document.querySelector('#dropdown')
      // createPopper(button, dropdown)
    })

    const selectedUserId = computed(() => {
      let tmp_id = props.idUser;
      return parseInt(atob(tmp_id));
    })

    function updateModal() {
      showModal.value = !showModal.value;
    }

    return {
      showModal,
      selectedUserId,
      updateModal
    }
  }
}
</script>

<style scoped>
.dropdown-menu {
  width: 30px;
}

.btn:hover {
  color: black;
  background-color: rgba(143, 147, 160, 0.16);

}

</style>