<template>

  <div class="row">
    <div class="col-4">
      <label>Filter By:</label>
      <div class="input-group mb-1">

        <input class="form-control" type="search" v-model="searchParam"
               placeholder="Name, Email, ESU, District, or School" aria-label="Search Query"
               aria-describedby="button-addon2"/>
      </div>
    </div>
    <div class="col-4 offset-4">
      <h1 style="font-weight: bold; color: black;">Removed Users</h1>
    </div>
  </div>
  <div class="row">
    <UserTable :field-headers="fields" :user-data="userList" :search-param="searchParam"/>
  </div>

</template>

<script>
import {ref, onBeforeMount, computed} from "vue";
import axios from "axios";
import {API_URL} from "../../../Constants";
import {useStore} from "vuex";
import UserTable from "@/views/User/UserTable";

export default {
  name: "RemovedUserList",
  components: {UserTable},
  setup() {
    const store = useStore();
    const authToken = computed(() => store.getters['auth/authenticated']);
    const searchParam = ref("");
    const userList = ref([]);
    const fields = ref({});

    onBeforeMount(() => {
      getRemovedUsers();
    })

    async function getRemovedUsers() {
      setFieldHeadersAllUsersSuperUserView();
      let get_uri = API_URL + "/user/showRemoved";
      await axios.get(get_uri, {
        headers: {
          Authorization: "Bearer " + authToken.value
        }
      }).then((response) => {
        response.data.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
        userList.value = setSchoolUserObjects(response.data);
      })
    }


    function setFieldHeadersAllUsersSuperUserView() {
      fields.value = {
        name: "Name",
        email: "Email",
        esu: "ESU",
        district: "District",
        school: "School",
        educationalOrganization: "Outstate Organization",
        state: "State",
        country: "Country",
        registrationDate: "Registration Date",
        position: "Role",
        accountStatus: "Account Inactive/Active"
      }
    }

    function setSchoolUserObjects(userList) {
      let userObjList = [];
      for (let i = 0; i < userList.length; i++) {

        let user = {
          name: userList[i]['firstName'] + " " + userList[i]['lastName'],
          email: userList[i]['email'],
          state: userList[i]['stateName'],
          country: userList[i]['countryName'],
          esu: (userList[i]['esuName'] !== null && userList[i]['esuName'] !== undefined) ? userList[i]['esuName'] : "",
          district: (userList[i]['districtName'] !== null && userList[i]['districtName'] !== undefined) ? userList[i]['districtName'] : "",
          school: (userList[i]['schoolName'] !== null && userList[i]['schoolName'] !== undefined) ? userList[i]['schoolName'] : "",
          accountStatus: userList[i]['accountEnabled'] !== null ? userList[i]['accountEnabled'] : false,
          registrationDate: userList[i]['registrationDate'],
          idUser: btoa(userList[i]['idUser']),
          position: userList[i]['positionName'],
          educationalOrganization: (userList[i]['outStateOrgName'] !== null && userList[i]['outStateOrgName'] !== undefined)
              ? userList[i]['outStateOrgName'] : "",
          canvasId: userList[i]['canvasId']
        }
        userObjList.push(user);
      }
      return userObjList;
    }

    return {
      searchParam,
      userList,
      fields
    }
  }
}
</script>

<style scoped>

</style>