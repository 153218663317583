<template>

  <!--  <div class="container-lg mt-2" style="overflow-y: scroll; height: 400px;">-->
  <div class="container-lg mt-2" v-if="showCount">
    <table id="userTable" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th v-for="(value, name, index) in fieldHeaders" :key="index" @click="sortTable(name)">{{ value }}
          <i v-if="name === 'position'"
             :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
             aria-label='Sort Icon'>
            <b-tooltip target="tooltip-target-role" triggers="hover" title="User's Privilege/Access Level"
                       style="font-style: normal;" class="bi bi-info-circle">
            </b-tooltip>
          </i>
          <i v-else-if="name === 'accountStatus'"
             :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
             aria-label='Sort Icon'
             id="tooltip-target-status">
            <b-tooltip target="tooltip-target-status" triggers="hover" class="bi bi-info-circle"
                       title="User's account is inactive or active"
                       style="font-style: normal;">
            </b-tooltip>
          </i>
          <i v-else :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
             aria-label='Sort Icon'></i></th>
        <th v-if="viewingParas">Reports</th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="item in filteredList" :key="item">

        <td v-for="(value, name, index) in fieldHeaders" :key="index">

          <p v-if="name === 'name'">
            <!--            <template v-if="showDropdown">-->
            <!--              <teleport to="dropdown">-->
            <!--                <UserMenuManagementDropdown :id-user="menuItemUser" :user-name="selectedUserName"/></teleport>-->
            <!--            </template>-->

            <!--            <b-tooltip target="_self" title="Opens menu to add user to your groups.">-->
            <!--              &lt;!&ndash;              <button class="bi bi-three-dots-vertical" @click="showDropdown = !showDropdown">&ndash;&gt;-->
            <!--              &lt;!&ndash;                &ndash;&gt;-->
            <!--              &lt;!&ndash;              </button>&ndash;&gt;-->
            <!--              <button class="bi bi-three-dots-vertical" @click="updateShowDropdown(item['idUser'], item[name])"-->
            <!--                      alt="Opens menu to add user to your groups."></button>-->
            <!--            </b-tooltip>-->

            <UserMenuManagementDropdown :id-user="item['idUser']" :groups="userGroups"
                                        :show-report-dropdown="item['enrollmentType'] !== 4"
            />

            <router-link :to="{name: 'ViewUser', params: { idUser: item['idUser']}}">
              {{ item[name] }}
            </router-link>
          </p>
          <p v-else-if="name === 'email'" style="word-break: break-all;"> {{ item[name] }}</p>
          <p v-else-if="name !== 'accountStatus'">{{ item[name] }}</p>


          <ToggleSwitch v-else :is-active="item['accountStatus']"
                        @checked="toggleEnabledV2(item['email'], $event, item['idUser'])"/>

        </td>
        <td>
          <router-link v-if="item['enrollmentType'] !== 4"
                       :to="{name: 'CertificationReport', params: {idUser: item['idUser']}}">{{ item['name'] }}'s
            Progress
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <label v-if="showCount">Users Found: {{ userCount }}</label>
  </div>
  <div v-if="showModal">
    <Teleport to="body">
      <UserGroupMenu
          :id-user="menuItemUser"
          :groups="userGroups"
          @close="showModal = false"
          :user-name="selectedUserName"
      />
    </Teleport>
  </div>

</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {sortBy} from "lodash/collection";
import {API_URL} from "../../../Constants";
import axios from "axios";
import Swal from "sweetalert2";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch";
import {isEmpty} from "lodash/lang";
import {onBeforeRouteLeave} from "vue-router";
import {useStore} from "vuex";
import {allGroupsByUser} from "@/views/SuperUserViews/UserGroups/GroupApiRoutes";
import UserGroupMenu from "@/views/SuperUserViews/UserGroups/UserGroupMenu";
import UserMenuManagementDropdown from "@/components/User/UserMenu/UserMenuManagementDropdown";

export default {
  name: "UserTable",
  // eslint-disable-next-line vue/no-unused-components
  components: {UserMenuManagementDropdown, ToggleSwitch, UserGroupMenu},
  props: {
    fieldHeaders: Object,
    userData: Array,
    authToken: String,
    viewingParas: Boolean,
    searchParam: String,
  },
  setup(props, context) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    let sort = ref(false);
    let updatedList = ref([]);
    let isLoading = ref(true);
    let searchQuery = ref("");
    let prevSearchCol = ref("");
    let icon = ref(null);
    let isHoveringAccountInfo = ref(false);
    let storedReverse = ref(false);
    const showDropdown = ref(false);
    const showModal = ref(false);
    const menuItemUser = ref(-1);
    const userGroups = ref([]);
    const selectedUserName = ref("");
    //icon unicode U+24D8

    onBeforeMount(() => {
      if (localStorage.getItem('userSearchResults')) {
        updatedList.value = JSON.parse(localStorage.getItem('userSearchResults'));
        isLoading.value = false;
      }

      if (localStorage.getItem('userFilterQuery')) {
        searchQuery.value = localStorage.getItem('userFilterQuery');
      }
      icon.value = '\u24D8'; // info icon in unicode
      getUserGroups();
    })

    onBeforeRouteLeave(() => {
      let sort_reversed_temp = storedReverse.value ? 'true' : 'false';
      localStorage.setItem('userSortReversed', sort_reversed_temp);
    })

    watch(() => props.userData, (first, second) => {
      isLoading.value = true;
      updatedList.value = props.userData;
      isLoading.value = false;
      prevSearchCol.value = "";
      if (localStorage.getItem('userSortReversed')) {
        sortOnReroute(localStorage.getItem('userSortCol'), (localStorage.getItem('userSortReversed') === 'true'))
      }
      localStorage.setItem('userSearchResults', JSON.stringify(updatedList.value));
    })
    //
    // watch(() => props.fieldHeaders, (first, second) => {
    //   updatedList = props.fieldHeaders;
    // })

    watch(() => props.viewingParas, (first, second) => {

    })

    watch(() => props.searchParam, (first, second) => {
      searchQuery.value = props.searchParam;
    })

    const userCount = computed(() => {
      return filteredList.value.length;
    })

    const showCount = computed(() => {
      return !isEmpty(props.fieldHeaders);
    })

    async function toggleEnabled(email, enabled) {
      const put_uri = API_URL + "/user/enableUser";
      await axios.put(put_uri, {
            email: email,
            enabled: !enabled
          },
          {
            headers: {
              Authorization: "Bearer " + props.authToken
            }
          }).then((result) => {
        Swal.fire({
          title: "User Access Updated",
          icon: 'success'
        }).finally(() => {
          context.emit("enabledUser", "refresh");
        })
      })
    }

    async function toggleEnabledV2(email, enabled, idUser) {
      const put_uri = API_URL + "/user/v2/enableUser/" + parseInt(atob(idUser));
      await axios.put(put_uri, {
            email: email,
            enabled: !enabled
          },
          {
            headers: {
              Authorization: "Bearer " + props.authToken
            }
          }).then((result) => {
        Swal.fire({
          title: "User Access Updated",
          icon: 'success'
        }).finally(() => {
          context.emit("enabledUser", "refresh");
        })
      })
    }

    const sortTable = (col) => {
      sort.value = true;
      if (col !== prevSearchCol.value) {
        storedReverse.value = false;
        if (col !== "accountStatus") {
          updatedList.value = sortBy(props.userData, col);
        } else {
          updatedList.value = sortBy(props.userData, col);
        }
      } else {
        storedReverse.value = !storedReverse.value;
        updatedList.value = updatedList.value.reverse();
      }
      prevSearchCol.value = col;
      localStorage.setItem('userSortCol', prevSearchCol.value);
      localStorage.setItem('userSearchResults', JSON.stringify(updatedList.value));
    }

    const sortOnReroute = (col, reversed) => {
      updatedList.value = sortBy(props.userData, col);
      if (reversed) {
        updatedList.value = updatedList.value.reverse();
      }
      prevSearchCol.value = col;
      storedReverse.value = reversed;
      localStorage.removeItem('userSortReversed');
      localStorage.setItem('userSortCol', prevSearchCol.value);
      localStorage.setItem('userSearchResults', JSON.stringify(updatedList.value));
    }

    const filteredList = computed(() => {
      let ret_val = updatedList.value.filter((user) => {
        // return (user.name.toLowerCase().indexOf(searchQuery.value.toLowerCase()) !== -1);
        const name = user.name.toLowerCase();
        const email = user.email.toLowerCase();
        const esu = user.esu !== null ? user.esu.toLowerCase() : "";
        const district = (user.district !== null && user.district !== undefined) ? user.district.toLowerCase() : "";
        const school = (user.school !== null && user.school !== undefined) ? user.school.toLowerCase() : "";
        const state = (user.state !== null && user.state !== undefined) ? user.state.toLowerCase() : "";
        const country = (user.country !== null && user.country !== undefined) ? user.country.toLowerCase() : "";
        const outstate_org = (user.educationalOrganization !== null && user.educationalOrganization !== undefined) ? user.educationalOrganization.toLowerCase() : "";
        /*        const account_status = user.accountStatus !== null ? user.accountStatus : false;
                const searching_enabled_users = searchQuery.value.toLowerCase().includes('enable');
                const searching_disabled_users = searchQuery.value.toLowerCase().includes('disable');*/


        return name.includes(searchQuery.value.toLowerCase()) || email.includes(searchQuery.value.toLowerCase())
            || (esu !== "" && esu.includes(searchQuery.value.toLowerCase())) || (district !== "" && district.includes(searchQuery.value.toLowerCase()))
            || (school !== "" && school.includes(searchQuery.value.toLowerCase())) || (state !== "" && state.includes(searchQuery.value.toLowerCase()))
            || (country !== "" && country.includes(searchQuery.value.toLowerCase())) || (outstate_org !== "" && outstate_org.includes(searchQuery.value.toLowerCase()))
            /*|| account_status === searching_disabled_users || account_status === searching_enabled_users*/;
      })
      let temp_arr = [];

      for (let i = 0; i < ret_val.length; i++) {
        temp_arr.push(JSON.parse(JSON.stringify(ret_val[i])));
      }
      // localStorage.setItem('userFilterQuery', searchQuery.value);
      localStorage.setItem('userFilterQuery', searchQuery.value);
      localStorage.setItem('filteredUserResults', JSON.stringify(temp_arr));

      return ret_val;
    })

    async function getUserGroups() {
      await axios.get(allGroupsByUser(), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        userGroups.value = result.data;
      })
    }

    function updateModal(idUser, name) {
      showModal.value = !showModal.value;
      menuItemUser.value = parseInt(atob(idUser));
      selectedUserName.value = name;
    }

    function updateShowDropdown(idUser, name) {
      showDropdown.value = !showDropdown.value;
      menuItemUser.value = parseInt(atob(idUser));
      selectedUserName.value = name;
    }

    return {
      // values
      updatedList,
      searchQuery,
      filteredList,
      isLoading,
      userCount,
      icon,
      isHoveringAccountInfo,
      showCount,
      prevSearchCol,
      storedReverse,
      showModal,
      userGroups,
      menuItemUser,
      selectedUserName,
      showDropdown,
      // functions
      sortTable,
      toggleEnabled,
      toggleEnabledV2,
      updateShowDropdown,
      updateModal
    }
  }
}
</script>

<style scoped>

table th:hover {
  background: #f2f2f2;
}

td {
  vertical-align: middle;
}

th {
  min-width: 75px;
}

</style>